<template>
  <!-- smartphone START -->
  <div class="scan-smartphone">
    <!-- qr-code:icon START -->
    <div class="icon">
      <img width="64" :src="iconSmartphone" />
    </div>
    <!-- qr-code:icon END -->

    <h1 v-html="$t('qrcode.scanqrcode')"></h1>

    <!-- qr-code:description START -->
    <div class="description">
      <p v-html="$t('terminal.smartphone.code')"></p>
    </div>
    <!-- qr-code:description START -->

    <!-- qr-code:code START -->
    <div class="code">
      <img :src="iconCode" />
    </div>
    <!-- qr-code:code END -->
  </div>
  <!-- smartphone END -->
</template>

<script>
import titleMixin from '@/mixins/titleMixin';

import ICONS from '../../utils/icons';

const iCode = require('../../assets/images/temp/qr-code.png');
const iCodeG = require('../../assets/images/temp/qr-code-green.png');

const {
  iSmartphone,
} = ICONS;

export default {
  name: 'smartphone',
  mixins: [titleMixin],
  data() {
    return {
      /**
       * Title (Page) - If "pageTitleFrom" exists - show simple Navigation...
       */
      pageTitleFromComponent: this.$t('scan.withthesmartphone'),

      iconCode: localStorage && localStorage.getItem('theme') === 'light' ? iCode : iCodeG,
      iconSmartphone: localStorage && localStorage.getItem('theme') === 'light' ? iSmartphone.defaultUrl : iSmartphone.greenUrl,
    };
  },
};
</script>
